@import "../css/variables.scss";
@mixin d-flex {
    display: flex;
}
@mixin d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin d-flex-space-between {
    display: flex;
    align-items: center;
	justify-content: space-between;
}
@mixin style-table-head {
    color: $grey-dark1;
    font-weight: $fw-bold;
	font-family: $font-family-sansmedium;
    font-size: 14px;
    line-height: 20px;
}
@mixin style-table-data {
    color: $grey-dark1;
	font-weight: $fw-reg;
    font-size: 14px;
    font-family: $font-family-sansmedium;
}
@mixin accordion-table-head {
    color: $grey-dark2;
	font-weight: $fw-reg;
    font-size: 12px;
    font-family: $font-family-sansmedium;
    line-height: 20px;
}
@mixin accordion-table-data {
    color: $grey-dark1;
    font-weight: $fw-bold;
	font-family: $font-family-sansregular;
    font-size: 14px;
}
@mixin results-table-head {
    color: $grey-dark2;
	font-weight: $fw-reg-bold;
    font-size: 12px;
    font-family: $font-family-sansmedium;
}
@mixin results-table-data {
    color: $grey-dark1;
    font-weight: $fw-bold;
	font-family: $font-family-sansregular;
    font-size: 14px;
}
@mixin sub-title {
    font-size: 24px;
    text-align: left;
    font-weight: $fw-med;
}