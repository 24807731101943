@font-face {
    font-family: 'honeywell_sansblack';
    src: url('honeywellsans-black-webfont.woff2') format('woff2'),
         url('honeywellsans-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'honeywell_sansbold';
    src: url('honeywellsans-bold-webfont.woff2') format('woff2'),
         url('honeywellsans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'honeywell_sansregular';
    src: url('honeywellsans-book-webfont.woff2') format('woff2'),
         url('honeywellsans-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'honeywell_sansextrabold';
    src: url('honeywellsans-extrabold-webfont.woff2') format('woff2'),
         url('honeywellsans-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'honeywell_sanslight';
    src: url('honeywellsans-light-webfont.woff2') format('woff2'),
         url('honeywellsans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'honeywell_sansmedium';
    src: url('honeywellsans-medium-webfont.woff2') format('woff2'),
         url('honeywellsans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}