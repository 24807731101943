@import "../../../../../../assets/css/variables.scss";
@import "../../../../../../assets/css/mixins.scss";

.system-analysis-panel {
	background-color: $grey-bg2;
	height: 100%;
	text-align: left;
	padding: 12px 15px;
	.content {
		border-bottom: 1px solid $grey2;
		margin-bottom: 12px;
		&:last-child {
			border-bottom: 0px;
		}
		.title {
			font-size: 20px;
			font-weight: $fw-med;
			padding-bottom: 5px;
		}
		.btn {
			min-width: 90px;
			padding: 9px 16px 7px;
			margin: 0 10px 10px 0;
			font-size: 12px;
			border: 1px solid $grey-dark1;
		}
		.selected-item {
			@include d-flex-space-between;
			.info {
				font-size: 14px;
				font-weight: $fw-bold;
				padding: 5px 0;
				text-align: left;
				font-family: $font-family-sansmedium;
				&:last-child {
					font-weight: $fw-xxbold;
					text-align: right;
				}
				span.refrigerant {
					font-family: $font-family-sansregular;
					font-weight: $fw-reg;
					padding-left: 5px;
				}
			}
		}
		.load-range {
			@include d-flex-space-between; 
			.info {
				font-size: 14px;
				font-weight: $fw-bold;
				padding: 5px 0;
				text-align: left;
				color: $grey1;
				&:last-child {
					font-family: $font-family-sansblack;
					font-weight: $fw-xxbold;
					text-align: right;
				}
			}
		}
		.small {
			font-size: 12px;
			margin-bottom: 12px;
			color: $grey9;
		}
		.warning-message {
			display: block;
			color: $red;
			font-size: 12px;
			.mesg {
				display: flex;
				align-items: center;
				font-weight: $fw-med;
				font-family: $font-family-sansbold;
				.warning-icon {
					padding-left: 4px;
				}
				span {
					padding: 4px 0 0 5px;
				}
			}
			span {
				display: block;
				font-family: $font-family-sansmedium;
			}
		}
		.form-group {
			text-align: left;
			padding-bottom: 5px;
			label {
				font-weight: $fw-bold;
				font-size: 14px;
				margin-bottom: 5px;
				font-family: $font-family-sansmedium;
			}
			.mandatory {
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: $light-red1;
				display: inline-block;
				position: relative;
				top: -2px;
			}
			.form-text {
				color: $grey9;
				font-size: 12px;
				font-weight: 500;
			}
			.error {
				color: $red1;
			}
			input.form-control.error-message,
			select.form-control.error-message {
				border: 1px solid $red1;
			}
			.form-control {
				appearance: none;
				border-radius: 0;
					&:focus {
						box-shadow: none;
						outline: 0;
					&:hover {
						border: 1px solid $grey32;
					}
				}
			}
			.angle-down-arrow {
				float: right;
				margin-top: -35px;
				margin-right: 0.5em;
				pointer-events: none;
				background-color: transparent;
				color: $grey3;
			}
			.angle-down-icon {
				width: 1.9em;
				height: 1.9em;
				stroke-width: 0;
			}
		}
		.radio-system div {
			display: inline-block;
			margin-right: 20px;
			font-size: 14px;
			span {
				padding-left:5px;
			}
		}
	}
}