@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";

.eco-header-results {
	@include d-flex-space-between;
	flex-wrap: wrap;
	margin: 20px auto 10px;
	background: $grey6;
	.overview {
		@include d-flex-space-between;
		flex: 67%;
		.eco-title {
			font-weight: $fw-bold;
			font-size: 18px;
			font-family: $font-family-sansmedium;
			color: $grey-dark1;
			padding-left: 15px;
		}
		.store-size {
			font-size: 14px;
			padding-right: 15px;
			span {
				font-weight: $fw-bold;
			}
			sup {
				top: -0.5em;
			}
			span.f-bold {
				font-weight: $fw-bold;
				font-family: $font-family-sansmedium;
			}
		}
	}
	.buttons {
		display: flex;
		justify-content: flex-end;
		background: $white;
		flex: 33%;
		text-align: end;
		.btn {
			&:last-child {
				margin: 0;
			}
		}
	}
	.download-icon {
		font-size: 26px;
		padding: 0;
		margin: 0;
		background-color: $blue;
		width: 54px;
		color: $white;
		height: 51px;
		align-items: center;
		justify-content: center;
		display: flex;
		margin-right: 10px;
		cursor: pointer;
	}
	.modal-loader-save {
		margin-top: 20%;
		.loader-save-content {
			min-height: 90px;
			text-align: center;
			vertical-align: middle;
			margin-top: 10%;
		}
	}
}

@media only screen and (max-width: 1280px) {
	.eco-header-results {
		.overview {
			flex: 54%;
			.eco-title {
				font-size: 14px;
			}
		}
		.buttons {
			flex: 36%;
			.btn {
				padding: 12px 16px 11px;
				font-size: 13px;
			}
		}
		.download-icon {
			height: auto;
		}
	}
}