@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";

.system-choice-table {
	width: 100%;
    margin: 0 auto;
	.panel-body {
	  padding: 0;
	  margin-top: 20px;
	  border: 1px solid $grey2;
	  .table-head {
		@include style-table-head;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        border-bottom: 1px solid $grey2;
	  }
	  .table-items {
		display: inline-flex;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		width: 20%;
		padding: 10px 0 0px 15px;
		.form-check-inline {
			margin-top: 4px;
		}
		span {
			padding-top: 4px;
		}
		&:last-child {
		  border-right: none;
		}
		&:nth-child(n+2) {
			top: 4px;
			position: relative;
		}
		&:nth-child(3) {
			padding-left: 7px;
		}
		&:nth-child(4) {
			padding-left: 16px;
		}
	  }
	  .table-data {
		@include style-table-data;
		display: inline-flex;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		width: 20%;
		padding: 10px 0 0px 15px;
		&:first-child {
			text-align: left;
		}
		&:nth-child(3) {
		  padding-left: 6px;
		}
		&:last-child {
		  padding-left: 16px;
		}
		.select-checkbox-disable input {
			color: $grey9;
			border: 1px solid $grey7 !important;
		}
		input[type="text"] {
			padding: .375rem .75rem;
			border: 1px solid $grey2;
			width: 70%;
			&:focus {
				border-color: $grey2;
				box-shadow: none;
				outline: 0;
			}
		}
		.angle-down-arrow {
			pointer-events: none;
			background-color: transparent;
			color: $grey3;
			position: relative;
			top: 3px;
			left: -30px;
		}
		.angle-down-icon {
			width: 1.9em;
			height: 1.9em;
			stroke-width: 0;
		}
        .form-control {
            display: block;
            width: 70%;
            padding: 0.375rem 0.75rem;
            font-size: 14px;
            font-weight: $fw-med;
            line-height: 1.5;
            color: $grey-dark1;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $grey25;
            appearance: none;
            border-radius: 0;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
			&:focus {
				outline: none;
				box-shadow:none;
			}
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 53px;
            height: 20px;
            input { 
                opacity: 0;
                width: 0;
                height: 0;
              }
          }
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $grey2;
            -webkit-transition: .4s;
            transition: .4s;
          }
          .slider:before {
            position: absolute;
            content: "";
            height: 20px;
            width: 20px;
            left: 3px;
            bottom: 1px;
            background-color: $white;
            -webkit-transition: .4s;
            transition: .4s;
          }
          input:checked + .slider {
            background-color: $blue;
          }
          input:focus + .slider {
            box-shadow: 0 0 1px $blue;
          }
          input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
          }
          .slider.round {
            border-radius: 50px;
			height: 22px;
          }
          .slider.round:before {
            border-radius: 50%;
          }
	}
	.select-checkbox-disable {
	  cursor: not-allowed;
	}
	.checkbox-disable {
	  cursor: not-allowed;
	  pointer-events: none;
	  opacity:0.5;
	}
	.form-check-input {
	  &:focus {
		border-color: none;
		outline: 0;
		box-shadow: none;
	  }
	}
	.form-check-input[type=checkbox] {
		border-radius: 0;
		margin-top: 2px;
	}
	.row_entries:last-child {
		padding-bottom: 20px;
	}
  }
}
.system-warning {
	padding-right: 10px;
	display: flex;
	align-items: center;
    justify-content: center;
	img {
		width: 25px;
	}
	span {
		padding-left: 5px;
		color: $red;
		font-weight: $fw-reg;
		font-family: $font-family-sansmedium;
	}
}