@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";
.parameter-title {
	font-size: 24px;
	font-weight: $fw-reg;
	text-align: left;
	padding-top: 20px;
}
.parameter-setup-table {
	width: 100%;
    margin: 0 auto;
    display: inline-flex;
	.panel-body {
	  padding: 0;
      width: 100%;
	  margin-top: 10px;
	  border: 1px solid $grey2;
	  .table-head {
		@include style-table-head;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        border-bottom: 1px solid $grey2;
		font-size: 12px;
		display: flex;
	  }
	  .no-border {
		border-bottom: 0px solid $grey2;
	  }
	  .table-items {
		display: inline-flex;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		padding: 10px 0 10px 15px;
		text-transform: uppercase;
		&:first-child {
			text-align: left;
		}
		&:last-child {
		  border-right: none;
		}
		&:nth-child(n+2) {
			justify-content: center;
		}
	  }
	  .table-items.expansion-type.col-md-3 {
		margin-right: 108px;
	  }
	  .table-data.expansion-type.col-md-3 {
		margin-right: 108px;
	  }
	  .table-data {
		@include style-table-data;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		padding: 10px 0 0 15px;
		text-align: left;
		&:last-child {
		  padding-bottom: 12px;
		}
		span.small-text {
			display: block;
    		font-size: 11px;
		}
		input[type="text"] {
			padding: .375rem .75rem;
			border: 1px solid $grey2;
			max-width: 80px;
			height: max-content;
			&:focus {
				border-color: $grey2;
				box-shadow: none;
				outline: 0;
			}
		}
		input.error {
			border: 1px solid $red;
		}
		&:nth-child(n+2) {
			justify-content: center;
			display: grid;
		}
		&:last-child {
			font-weight: $fw-xxbold;
		}
		.angle-down-arrow {
			pointer-events: none;
			background-color: transparent;
			color: $grey3;
			position: relative;
			top: 3px;
			left: -15px;
		}
		.angle-down-icon {
			width: 1.9em;
			height: 1.9em;
			stroke-width: 0;
		}
        .form-control {
            display: block;
            width: 70%;
			margin: 0 auto;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $grey-dark1;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $grey25;
            appearance: none;
            border-radius: 0;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
			&:focus {
				outline: none;
				box-shadow:none;
			}
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 53px;
            height: 20px;
            input { 
                opacity: 0;
                width: 0;
                height: 0;
			}
		}
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $grey2;
			-webkit-transition: .4s;
			transition: .4s;
		}
		.slider:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 3px;
			bottom: 1px;
			background-color: $white;
			-webkit-transition: .4s;
			transition: .4s;
		}
		input:checked + .slider {
			background-color: $blue;
		}
		input:focus + .slider {
			box-shadow: 0 0 1px $blue;
		}
		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}
		.slider.round {
			border-radius: 50px;
			height: 22px;
		}
		.slider.round:before {
			border-radius: 50%;
		
		}
		.form-group {
			width: 115px;
			label {
				font-weight: 700;
				font-size: 16px;
				margin-bottom: 5px;
			}
			.form-text {
				color: $grey9;
				font-size: 12px;
				font-weight: 500;
			}
			.error {
				color: $red1;
			}
			input.form-control.error-message,
			select.form-control.error-message {
				border: 1px solid $red1;
			}
			.form-control {
				appearance: none;
				border-radius: 0;
				&:focus {
					box-shadow: none;
					outline: 0;
				&:hover {
					border: 1px solid $grey32;
				}
				}
			}
			.angle-down-arrow {
				float: right;
				margin-top: -35px;
				margin-right: 0.5em;
				pointer-events: none;
				background-color: transparent;
				color: $grey3;
			}
			.angle-down-icon {
				width: 1.9em;
				height: 1.9em;
				stroke-width: 0;
			}
		}
	}
	.select-checkbox-disable {
	  cursor: not-allowed;
	}
	.checkbox-disable {
	  cursor: not-allowed;
	  pointer-events: none;
	  opacity:0.5;
	}
	.form-check-input {
	  &:focus {
		border-color: none;
		outline: 0;
		box-shadow: none;
	  }
	}
	.form-check-input[type=checkbox] {
		border-radius: 0;
	}
	}
	.panel-body.minimum-condensing {
		.table-items {
			&:last-child {
				justify-content: flex-end !important;
				padding-right: 24px;
			}
		}
		.table-data {
			&:last-child {
				justify-content: flex-end !important;
				position: relative;
				left: 12px;
			}
			&:nth-child(2) {
				justify-content: flex-end !important;
			}			
		}

	}
}