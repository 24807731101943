@import "../../../../assets/css/variables.scss";
@import "../../../../assets/css/mixins.scss";
.results-nav {
	border: 1px solid $grey2;
	.nav-link {
		color: $blue;
		font-weight: $fw-xbold;
		background-color: $white;
		width: 100%;
		border-bottom: 6px solid $blue;
	}
	.nav-item {
		width: 50%;
	}
}
.nav-tabs {
	.nav-link.active {
		color: $blue;
		font-weight: $fw-xbold;
		background-color: $white;
		width: 100%;
		border-bottom: 6px solid $blue;
	}
	.nav-link {
		margin-bottom: 0px;
		font-size: 17px;
		background: 0 0;
		color: $blue;
		font-weight: $fw-reg;
		border: 0px solid transparent;
		border-top-left-radius: 0rem;
		border-top-right-radius: 0rem;
		width: 100%;
	}
}
.results-graph {
    margin: 20px 0;
    border: 1px solid $grey2;
    // .graph-group {
    //     width:80%;
    //     margin-left:10%;
    //     height:80%;
    // }    
    .graph-bubble {
        width:70%;
        margin-left:15%;
        height:70%;
        .x-axis-arrow {
            position: absolute;
            left: 16%;
            padding-top: 10%;
        }
        .y-axis-arrow {
            position: relative;
            left: -37px;
            top: -10px;
        }
    }
    .graph-bubble-1 {
        width:70%;
        margin-left:15%;
        height:70%;
        .x-axis-arrow {
            position: absolute;
            left: 16%;
            padding-top: 10%;
        }
        .y-axis-arrow {
            position: relative;
            left: -37px;
            top: -10px;
        }
    }
    .graph-bubble-2 {
        width:70%;
        margin-left:15%;
        height:70%;
        .x-axis-arrow {
            position: absolute;
            left: 16%;
            padding-top: 10%;
        }
        .y-axis-arrow {
            position: relative;
            left: -37px;
            top: -10px;
        }
    }
    .graph-info {
        background-color: $grey-bg1;
        padding: 3px 8px;
        font-size: 14px;
        margin: 0;
        text-align: left;
        span {
            font-weight: $fw-xbold;
            font-family: $font-family-sansbold;
            padding-right: 10px;
        }
    }
    .graph-title {
        font-size: 24px;
        font-weight: $fw-reg;
        font-family: $font-family-sansregular;
        text-align: left;
        padding: 10px 0 10px 15px;
        span {
            display: block;
            line-height: normal;
        }
    }
    .graph img {
        max-width: 100%;
    }
}
.sensitivity-selection {
    .btn.btn-run {
        padding: 8px 36px 7px;
        font-size: 14px;
        margin-top: 28px;
        min-width: auto;
    }
    .col-md-1.col-xs-12.d-flex.pt-2 {
        padding-left: 0;
    }
    .form-group {
        text-align: left;
        padding-bottom: 5px;
        label {
            font-weight: $fw-bold;
            font-size: 14px;
            margin-bottom: 5px;
            font-family: $font-family-sansmedium;
        }
        .mandatory {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $light-red1;
            display: inline-block;
            position: relative;
            top: -2px;
        }
        .form-text {
            color: $grey9;
            font-size: 12px;
            font-weight: 500;
        }
        .error {
            color: $red1;
        }
        input.form-control.error-message,
        select.form-control.error-message {
            border: 1px solid $red1;
        }
        .form-control {
            appearance: none;
            border-radius: 0;
                &:focus {
                    box-shadow: none;
                    outline: 0;
                &:hover {
                    border: 1px solid $grey32;
                }
            }
        }
        .angle-down-arrow {
            float: right;
            margin-top: -35px;
            margin-right: 0.5em;
            pointer-events: none;
            background-color: transparent;
            color: $grey3;
        }
        .angle-down-icon {
            width: 1.9em;
            height: 1.9em;
            stroke-width: 0;
        }
        .item-pdf-only{
               display: none !important;
            }
       
    }
    .parameter-panel {
        position: absolute;
        width: 500px;
        background-color: $white;
        padding: 15px;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
        text-align: left;
        .panel-title {
            font-size: 24px;
        }
        .electricity-price {
            padding-top: 10px;
            font-size: 12px;
            font-weight: $fw-bold;
            color: $grey1;
            .fwbold {
                font-weight: $fw-xxbold;
                font-family: $font-family-sansblack;
            }
        }
    }
}
.sensitivity-table {
	  width: 100%;
      margin: 0 auto;
      display: inline-flex;
	 .panel-body {
	  padding: 0;
      width: 100%;
	  margin-top: 10px;
	  border: 1px solid $grey2;
	  .table-head {
		@include style-table-head;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: normal;
        border-bottom: 1px solid $grey2;
        font-size: 12px;
        }
        .table-items {
        display: inline-flex;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        align-self: auto;
        order: 0;
        padding: 10px 0 10px 15px;
        text-align: center;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            border-right: none;
        }
        &:nth-child(n+2) {
            justify-content: center;
        }
        }
	  .table-data {
		@include style-table-data;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		padding: 10px 0 0 15px;
		text-align: left;
		&:last-child {
		  padding-bottom: 12px;
		}
		input[type="text"] {
			padding: .375rem .75rem;
			border: 1px solid $grey2;
			max-width: 120px;
			&:focus {
				border-color: $grey2;
				box-shadow: none;
				outline: 0;
			}
		}
		&:nth-child(n+2) {
			text-align: center;
            font-weight: $fw-bold;
		}
		&:last-child {
			font-weight: $fw-xxbold;
		}
		.angle-down-arrow {
			pointer-events: none;
			background-color: transparent;
			color: $grey3;
			position: relative;
			top: 3px;
			left: -15px;
		}
		.angle-down-icon {
			width: 1.9em;
			height: 1.9em;
			stroke-width: 0;
		}
        .form-control {
            display: block;
            width: 80%;
            margin: 0 auto;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $grey-dark1;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $grey25;
            appearance: none;
            border-radius: 0;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            &:focus {
            outline: none;
            box-shadow:none;
            }
            &.error-message {
                border: 1px solid $red1;
            }
        }
		.form-group {
			width: 125px;
			label {
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 5px;
        }
        .form-text {
            color: $grey9;
            font-size: 12px;
            font-weight: 500;
        }
        .error {
            color: $red1;
        }
        input.form-control.error-message,
        select.form-control.error-message {
            border: 1px solid $red1;
        }
        .form-control {
            appearance: none;
            border-radius: 0;
            &:focus {
                box-shadow: none;
                outline: 0;
            &:hover {
                border: 1px solid $grey32;
            }
            }
        }
        .angle-down-arrow {
            float: right;
            margin-top: -35px;
            margin-right: 0.5em;
            pointer-events: none;
            background-color: transparent;
            color: $grey3;
        }
        .angle-down-icon {
            width: 1.9em;
            height: 1.9em;
            stroke-width: 0;
        }
		}
	}
	}
}
.small-text
{
    font-size: 11px;
    font-weight: 500;
    text-align: left;
}