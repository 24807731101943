@import "../../../../assets/css/variables.scss";
.dashboard {
	display: flex;
}
.heading {
	background: $grey-bg1;
	display: flex;
	width: 100%;
	padding: 15px 20px;
	font-weight: $fw-xxbold;
	font-size: 17px;
	line-height: 20px;
	letter-spacing: 0.708333px;
	color: $grey-dark1;
	margin-bottom: 30px;
}