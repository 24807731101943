@import "../../../assets/css/variables.scss";
@import "../../../assets/css/mixins.scss";
.modal-loader-save {
	margin-top: 20%;
	.loader-save-content {
		min-height: 90px;
		text-align: center;
		vertical-align: middle;
		margin-top: 10%;
	}
}
.save-session {
	margin-top: 9%;
	.save-session-content {
		text-align: center;
		vertical-align: middle;
		padding: 5px 20px 20px 20px;
	}
	.hyperlink {
		cursor: pointer;
		font-size: 16px;
		color: $blue;
		font-weight: $fw-bold;
		display: initial;
	}
	p {
		font-size: 13px;
		margin: 0;
	}
	.btn-container .btn {
		padding: 12px 24px 11px;
	}
	.warning-text {
		color: $red;
		font-size: 12px;
	}
	.form-group {
		display: block;
		text-align: left;
		label {
			padding-right: 10px;
			font-size: 14px;
			font-weight: $fw-reg-bold;
		}
		.form-control {
            display: block;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $grey-dark1;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $grey25;
            appearance: none;
            border-radius: 0;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
			&:focus {
				outline: none;
				box-shadow:none;
			}
        }
		.angle-down-arrow {
            float: right;
            margin-top: -35px;
            margin-right: 0.5em;
            pointer-events: none;
            background-color: transparent;
            color: $grey3;
        }
        .angle-down-icon {
            width: 1.9em;
            height: 1.9em;
            stroke-width: 0;
        }
		.mandatory {
			background: $red;
			border-radius: 50%;
			display: inline-block;
			height: 8px;
			position: relative;
			top: -2px;
			width: 8px;
		}
	}
}
.choose-simulation {
	width: 100%;
	.warning-container {
		padding: 15px 20px 25px;
		width: 90vw;
		max-width: 925px;
	}
	.filter-block {
		@include d-flex-space-between;
		margin-bottom: 20px;
		.list-items {
			border: 1px solid $grey20;
			padding: 10px;
		}
		.form-check-input {
			border-color: $grey4;
			font-size: 14px;
			&:focus {
				border-color: $grey4;
				outline: 0;
				box-shadow: none;
			}
			&:checked {
				background-color: $black;
				border-color: $black;
			}
		}
		.form-check-input[type=checkbox] {
			border-radius: 0;
			margin: 2px 5px 0 0;
		}
	}
	.search-bar {
		width: 45%;
		display: flex;
		padding-top: 8px;
		.form-group.has-search {
			width: 90%;
		}
		.form-control-feedback {
			position: absolute;
			z-index: 2;
			display: block;
			width: 2.375rem;
			height: 2.375rem;
			line-height: 2.375rem;
			text-align: center;
			cursor: pointer;
			color: $grey1;
			right: 20px;
			font-size: 20px;
			font-weight: $fw-xbold;
			padding-top: 6px;
		}
		input[type=text] {
			padding: 0.375rem 0.75rem;
			border-bottom: 1px solid $grey20;
			border-radius: 0;
			border-left: 0;
			border-right: 0;
			border-top: 0;
		}
	}
	.btn-container {
		float: right;
		.btn {
			padding: 10px 20px 11px;
			min-width: 200px;
			font-size: 14px;
			font-weight: $fw-xbold;
			font-family: $font-family-sansbold;
		}
		.btn-disabled {
			color: $grey1;
			background-color: $grey2;
			border: 1px solid $grey2;
			cursor: not-allowed;
			pointer-events: all !important;
			&:hover {
				  color: $grey1;
			  }
		  }
	}
	.simulation-table {
		width: 100%;
		margin: 0 auto;
		display: inline-flex;
		margin-bottom: 20px;
	   .panel-body {
		padding: 0;
		width: 100%;
		margin-top: 10px;
		border: 1px solid $grey2;
		.table-head {
		  @include style-table-head;
		  display: flex;
		  flex-direction: row;
		  flex-wrap: nowrap;
		  justify-content: center;
		  align-items: center;
		  align-content: normal;
		  border-bottom: 1px solid $grey2;
		  font-size: 12px;
		  }
		  .table-items {
		  display: inline-flex;
		  flex-grow: 0;
		  flex-shrink: 1;
		  flex-basis: auto;
		  align-self: auto;
		  order: 0;
		  padding: 10px 0 10px 15px;
		  text-align: center;
		  &:first-child {
			  text-align: left;
		  }
		  &:nth-child(2) {
			padding-left: 12px;
		  }
		  &:nth-child(3) {
			position: relative;
			left: -9px;
		  }
		  &:last-child {
			  position: relative;
			  border-right: none;
			  left: -28px;
		  }
		  }
		.table-data {
		  @include style-table-data;
		  flex-grow: 0;
		  flex-shrink: 1;
		  flex-basis: auto;
		  align-self: auto;
		  order: 0;
		  padding: 10px 0 0 15px;
		  text-align: left;
		  &:last-child {
			padding-bottom: 12px;
		  }
	    }
		.table-row {
			cursor: pointer;
			&:nth-child(even) {
				background: $grey6;
			}
			.no-results {
				font-weight: $fw-xbold;
			}
		}
		.table-row.selected-row {
			background: $grey28;
		}
	  }
  }
  .filter-items {
	width: 100%;
	max-height: 205px;
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	&:first-child {
		padding-top: 0;
	}
  }
}
.modal-content {
	.warning-title {
		font-weight: $fw-bold;
		font-size: 18px;
		color: $grey-dark1;
		margin: 0;
		text-align: center;
	}
	.close-icon {
		font-size: 30px;
		text-align: right;
		cursor: pointer;
	}
}

.eco-header {
	@include d-flex-space-between;
	flex-wrap: wrap;
	margin: 20px auto 10px;
	background: $grey6;
	.overview {
		@include d-flex-space-between;
		flex: 70%;
		.eco-title {
			font-weight: $fw-bold;
			font-size: 18px;
			font-family: $font-family-sansmedium;
			color: $grey-dark1;
			padding-left: 15px;
		}
		.store-size {
			font-size: 14px;
			padding-right: 15px;
			span {
				font-weight: $fw-bold;
			}
			sup {
				top: -0.5em;
			}
			span.f-bold {
				font-weight: $fw-bold;
				font-family: $font-family-sansmedium;
			}
		}
	}
	.buttons {
		background: $white;
		flex: 30%;
		text-align: end;
		.btn {
			&:last-child {
				margin: 0;
			}
		}
	}
}
.simulation-header {
	@include d-flex-space-between;
	flex-wrap: wrap;
	margin: 20px auto 10px;
	background: $grey6;
	.overview {
		@include d-flex-space-between;
		flex: 63%;
		.eco-title {
			font-weight: $fw-bold;
			font-size: 18px;
			font-family: $font-family-sansmedium;
			color: $grey-dark1;
			padding-left: 15px;
		}
		.store-size {
			font-size: 14px;
			padding-right: 15px;
			span {
				font-weight: $fw-bold;
			}
			sup {
				top: -0.5em;
			}
			span.f-bold {
				font-weight: $fw-bold;
				font-family: $font-family-sansmedium;
			}
		}
	}
	.buttons {
		background: $white;
		flex: 37%;
		text-align: end;
		.btn {
			&:last-child {
				margin: 0;
			}
		}
	}
}
@media only screen and (max-width: 1280px) {
	.eco-header {
		.overview {
			flex: 62%;
			.eco-title {
				font-size: 14px;
			}
		}
		.buttons {
			flex: 38%;
			.btn {
				padding: 12px 16px 11px;
				font-size: 13px;
			}
		}
	}
	.simulation-header {
		.overview {
			flex: 62%;
			.eco-title {
				font-size: 14px;
			}
		}
		.buttons {
			flex: 38%;
			.btn {
				padding: 12px 16px 11px;
				font-size: 13px;
			}
		}
	}
}