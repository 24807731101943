@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";
.toggle-table {
    text-align: right;
    font-size: 14px;
    font-weight: $fw-bold;
    font-family: $font-family-sansregular;
    color: $blue;
    cursor: pointer;
    padding: 5px 0;
}
.results-table {
	width: 100%;
    margin: 0 auto;
	.panel-body {
	  padding: 0;
	  border: 1px solid $grey2;
	  font-size: 15px;
	  .table-head {
		@include results-table-head;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        border-bottom: 1px solid $grey2;
        background: $grey-bg1;
	  }
	  .table-items {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 8px 0 8px 15px;
		&:first-child {
			text-align: left;
			justify-content: left;
            background: $white;
		}
		&:last-child {
		  border-right: none;
		}
        &:nth-child(2) {
            padding-left: 4px;
        }
        &:nth-child(3) {
            background: $white;
            padding-left: 4px;
        }
		.checkbox-label {
			text-align: left;
		}
	  }
      .table-items.type.col-md-3 {
        display: block;
        span {
          display: block;
          font-size: 12px;
          font-family: $font-family-sansregular;
          padding-top: 5px;
        }
      }
	  .table-items.col-md-4 {
		  padding: 0 !important;
	  }
	  .table-data {
		@include results-table-data;
		display: inline-flex;
		padding: 8px 0 8px 15px;
        background: $grey-bg1;
		&:first-child {
			text-align: left;
            background: $white;
            span {
                display: contents;
            }
            }
            &:last-child {
                margin-left: 0;
            }
            &:nth-child(n+2) {
                justify-content: center;
            }
            &:nth-child(2) {
                padding-left: 2px;
            }
            &:nth-child(3) {
                background: $white;
                padding-left: 3px;
            }
	    }
    }
}