@import "../../../../assets/css/variables.scss";
@import "../../../../assets/css/mixins.scss";

.store-form {
	margin: 20px 0;
	.sub-head {
		font-size: 24px;
		text-align: center;
		font-weight: $fw-med;
		margin: 0 0 5px 0;
	}
	.graph-head {
		position: relative;
		top: 20px;
		text-align: left;
		font-weight: $fw-bold;
	}
	.form-bg {
		background-color: $grey-bg2;
		padding: 24px;
		max-height: 284px;
	}
	.form-group {
		text-align: left;
		padding-bottom: 12px;
		label {
			font-weight: $fw-bold;
			font-size: 14px;
			margin-bottom: 5px;
			font-family: $font-family-sansmedium;
		}
		.mandatory {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $light-red1;
			display: inline-block;
			position: relative;
			top: -2px;
		}
		.form-text {
			color: $grey9;
			font-size: 12px;
			font-weight: $fw-reg;
		}
		.error {
			color: $red1;
		}
		input.form-control.error-message,
		select.form-control.error-message {
			border: 1px solid $red1;
		}
		.form-control {
			appearance: none;
			border-radius: 0;
			&:focus {
				box-shadow: none;
				outline: 0;
			&:hover {
				border: 1px solid $grey32;
			}
			}
		}
		.angle-down-arrow {
			float: right;
			margin-top: -35px;
			margin-right: 0.5em;
			pointer-events: none;
			background-color: transparent;
			color: $grey3;
		}
		.angle-down-icon {
			width: 1.9em;
			height: 1.9em;
			stroke-width: 0;
		}
	}
}
.store-location {
	border-top: 1px solid $grey2;
	padding-top: 10px;
	.pl-0 {
		padding-left: 0;
	}
	.pr-0 {
		padding-right: 0;
	}
	.store-size-table {
		.accordion-item {
			&:last-of-type {
				.accordion-button {
					&:not(.collapsed) {
						background-color: $grey28;
					}
					&:hover {
						cursor: default;
					}
					&:focus {
						background-color: transparent;
					}
				}
			}
		}
	}
}