@import "../fonts/fonts.scss";
$white: #fff;
$black: #000;
$blue: #1274B7;
$blue1: #1792E5;
$blue2: #0e5f95;
$blue3: #286090;
$dark-blue: #1272b2;
$blue-hov: #00629b;
$light-blue:#f0f2f7;
$contrast-blue: #137bc0;
$green: #7eb338;
$green1: #628b2c;
$red: #ee3124;
$red1: #cc0000;
$light-red1: #F15A4F;
$orange: #f37021;
$orange1: #ff8c00;
$fw-light: 300;
$fw-med: 400;
$fw-reg: 500;
$fw-reg-bold: 600;
$fw-bold: 700;
$fw-xbold: 800;
$fw-xxbold: 900;
$grey1: #707070;
$grey2: #d0d0d0;
$grey3: #9b9b9b;
$grey4: #808080;
$grey5: #d7d7d7;
$grey6: #f7f7f7;
$grey7: #e0e0e0;
$grey8: #404040;
$grey9: #a0a0a0;
$grey10: #979797;
$grey11: #b0b0b0;
$grey12: #595858;
$grey13: #d8d8d8;
$grey14: #f0f0f0;
$grey15: #6c6c6c;
$grey16: #e8e8e8;
$grey17: #6e6e6e;
$grey18: #e6e6e6;
$grey19: #4a4a4a;
$grey20: #C4C4C4;
$grey21: #c0c0c0;
$grey22: #bababa;
$grey23: #e7e7e7;
$grey24: #e5e5e5;
$grey25: #e4e4e4;
$grey26: #f8f8f8;
$grey27: #C9C7C7;
$grey28: #D0E9F9;
$grey29: #757575;
$grey30: #F9F9F9;
$grey31: #1E1E1E;
$grey32: #ced4da;
$grey-dark1: #303030;
$grey-dark2: #606060;
$grey-dark3: #505050;
$grey-dark4: #3c3c3c;
$grey-dark5: #535353;
$grey-dark6: #464545;
$grey-dark7: #282c34;
$grey-dark8: #303130;
$grey-bg: #f6f6f6;
$grey-bg1: #F2F4F7;
$grey-bg2: #F3F3F3;

$xmediumbreakpoint: 992px;
$mediumbreakpoint: 768px;
$smallbreakpoint: 480px;

$mobile-nav-z-index: 900;
$color_transparent: transparent;

$lh-15: 1.5;

$font-family-sanslight: 'honeywell_sanslight';
$font-family-sansregular: 'honeywell_sansregular';
$font-family-sansmedium: 'honeywell_sansmedium';
$font-family-sansbold: 'honeywell_sansbold';
$font-family-sansblack: 'honeywell_sansblack';
$font-family-sansextrabold: 'honeywell_sansextrabold';