@import "../../assets/css/variables.scss";
@import "../../assets/css/mixins.scss";

.footer-content .scroll-to-top a:before {
  font-size: 20px;
  line-height: 40px;
}
.footer-content .icon:before {
  color: $white;
}
.icon-carat_up:before {
  content: "\005E";
}
.scroll-to-top.js-to-top.d-inline-block a.icon.icon-carat_up {
  display: none;
}
.row.py-5.footer-content__container,
.footer-content__bottom.row.py-5.py-md-3 {
  margin: 0;
}
.footer-content {
  .button--tertiary {
    a {
      background: transparent;
      color: $white;
      border: 2px solid $white;
      font-size: 14px;
      line-height: 18px;
      padding: 15px 5px;
      word-break: break-word;
      width: 232px;
      letter-spacing: .5px;
      margin: 10px;
      text-transform: uppercase;
      text-align: center;
      font-weight: $fw-xbold;
      transition: all .3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        border: 2px solid $blue-hov;
        background: transparent;
        color: $blue-hov;
      }
    }
  }
  .text-md-left {
    text-align: left !important;
  }
}
.scroll-to-top.js-to-top {
  float: right;
  position: relative;
  right: 100px;
  top: 65px;
  background: $blue;
  width: 40px;
  height: 40px;
  color: $white;
  border-radius: 50%;
  .arrow-ip {
    font-size: 24px;
    position: relative;
    top: 7px;
    cursor: pointer;
  }
}
.scroll-to-top.js-to-top.d-inline-block {
  display: none !important;
}