@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";

.load-setup {
	.title {
		font-size: 24px;
		text-align: left;
		padding-bottom: 5px;
	}
	.system-analysis-panel .content:first-child {
		border-bottom: 0;
		margin-bottom: 0;
	}
}
.load-setup-table {
	width: 100%;
    margin: 0 auto;
	.panel-body {
	  padding: 0;
	  border: 1px solid $grey2;
	  font-size: 15px;
	  .table-head {
		@include style-table-head;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
        border-bottom: 1px solid $grey2;
	  }
	  .table-head-upper {
		@include style-table-head;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: normal;
        align-items: normal;
        align-content: normal;
		.table-items {
			&:nth-child(3) {
			background: $white;
			}
		}
	  }
	  .table-items {
		.table-items-info{
			margin-left: 3px;
			text-transform:none; 
			sup {
				top: -.5em !important;
			}
		  }
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 8px 0 8px 15px;
		text-transform: uppercase;
		font-size: 14px;
		&:first-child {
			text-align: left;
			justify-content: left;
		}
		&:last-child {
		  border-right: none;
		  background: $grey-bg1;
		}
		&:nth-child(2) {
			background: $grey-bg1;
		}
		&:nth-child(3) {
			background: $grey-bg1;
		}
		.checkbox-label {
			text-align: left;
		}
	  }
	  .table-items.col-md-4 {
		  padding: 0 !important;
	  }
	  .table-data {
		@include style-table-data;
		display: inline-flex;
		padding: 8px 0 8px 15px;
		&:first-child {
			text-align: left;
		}
		&:last-child {
			font-family: $font-family-sansblack;
            font-weight: $fw-xxbold;
			background: $grey-bg1;
			margin-left: 0;
		}
		&:nth-child(n+2) {
			justify-content: center;
		}
		&:nth-child(2) {
			background: $grey-bg1;
			padding-left: 0;
		}
		&:nth-child(3) {
			background: $grey-bg1;
			padding-left: 0;
		}
		.checkbox-label {
			text-align: left;
		}
		.angle-down-arrow {
			pointer-events: none;
			background-color: transparent;
			color: $grey3;
			position: relative;
			top: 3px;
			left: -30px;
		}
		.angle-down-icon {
			width: 1.9em;
			height: 1.9em;
			stroke-width: 0;
		}
        .form-control {
            display: block;
            width: 70%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $grey-dark1;
            background-color: $white;
            background-clip: padding-box;
            border: 1px solid $grey25;
            appearance: none;
            border-radius: 0;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
			&:focus {
				outline: none;
				box-shadow:none;
			}
        }
        .switch {
            position: relative;
            display: inline-block;
            width: 53px;
            height: 20px;
            input { 
                opacity: 0;
                width: 0;
                height: 0;
              }
        }
		.slider {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: $grey2;
			-webkit-transition: .4s;
			transition: .4s;
		}
		.slider:before {
			position: absolute;
			content: "";
			height: 20px;
			width: 20px;
			left: 3px;
			bottom: 1px;
			background-color: $white;
			-webkit-transition: .4s;
			transition: .4s;
		}
		input:checked + .slider {
			background-color: $blue;
		}
		input:focus + .slider {
			box-shadow: 0 0 1px $blue;
		}
		input:checked + .slider:before {
			-webkit-transform: translateX(26px);
			-ms-transform: translateX(26px);
			transform: translateX(26px);
		}
		.slider.round {
			border-radius: 50px;
			height: 22px;
		}
		.slider.round:before {
			border-radius: 50%;
		}
	}
	.select-checkbox-disable {
	  cursor: not-allowed;
	}
	.checkbox-disable {
	  cursor: not-allowed;
	  pointer-events: none;
	  opacity:0.5;
	}
	.form-check-input {
	  &:focus {
		border-color: none;
		outline: 0;
		box-shadow: none;
	  }
	}
	.form-check-input[type=checkbox] {
		border-radius: 0;
	}
	input[type="text"] {
		padding: .375rem .75rem;
		border: 1px solid $grey2;
		max-width: 80px;
		&:focus {
			border-color: $grey2;
			box-shadow: none;
			outline: 0;
		}
	}
	input.error {
		border: 1px solid $red;
	}
}
}