@import "../../../assets/css/variables.scss";
.system-steps {
	border: 1px solid $grey2;
	width: 100%;
	overflow: auto;
	margin-top: 15px;
	.nav-setup {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
		li a {
			float: left;
			padding: 12px;
			text-decoration: none;
			font-size: 16px;
			text-align: center;
			width: 20%;
			font-weight: $fw-med;
			color: $grey-dark1;
			background-color: $white;
		}
		li a.active {
			border-bottom: 6px solid $blue;
			color: $blue;
			font-weight: $fw-bold;
		}
		li a.current-active {
			border-bottom: 6px solid $grey-bg1;
			color: $blue;
			background-color: $grey-bg1;
			font-weight: $fw-bold;
		}
	}
	.nav-setup-results {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
		}
		li a {
			float: left;
			padding: 12px;
			text-decoration: none;
			font-size: 16px;
			text-align: center;
			width: 50%;
			font-weight: $fw-med;
			color: $grey-dark1;
			background-color: $white;
		}
		li a.active {
			border-bottom: 6px solid $blue;
			color: $blue;
			font-weight: $fw-bold;
		}
		li a.current-active {
			border-bottom: 6px solid $grey-bg1;
			color: $blue;
			background-color: $grey-bg1;
			font-weight: $fw-bold;
		}
	}
	.nav-list {
		overflow: hidden;
		a {
			float: left;
			padding: 12px;
			text-decoration: none;
			font-size: 17px;
			text-align: center;
			width: 20%;
			font-weight: $fw-med;
			color: $grey-dark1;
			background-color: $white;
		}
		a.active {
			border-bottom: 6px solid $blue;
			color: $blue;
		}
	}
	.nav-list-child {
		overflow: hidden;
		background-color: $grey-bg1;
		a {
			float: left;
			padding: 12px;
			text-decoration: none;
			font-size: 16px;
			text-align: center;
			width: 20%;
			font-weight: $fw-med;
			color: $grey-dark1;
		}
		a.active {
			border-bottom: 6px solid $blue;
			color: $blue;
			font-weight: $fw-med;
		}
	}
	.nav-setup-child {
		overflow: hidden;
		background-color: $grey-bg1;
		ul {
			padding: 0;
			margin: 0;
			list-style: none;
			display: flex;
		}
		li {
			width: 20%;
			display: contents;
		}
		li a {
			float: left;
			padding: 12px;
			text-decoration: none;
			font-size: 15px;
			text-align: center;
			font-weight: $fw-med;
			color: $grey-dark1;
		}
		li a.active {
			border-bottom: 6px solid $blue;
			color: $blue;
			font-weight: $fw-bold;
		}
		li a.current-active {
			border-bottom: 6px solid $grey-bg1;
			color: $blue;
			background-color: $grey-bg1;
			font-weight: $fw-bold;
		}
	}
}
.set-default-link {
    font-weight: $fw-bold;
    display: flex;
    justify-content:flex-end;
    padding-top: 10px;
	color: $blue;
	cursor: pointer;
}
.mob-progress-bar {
	display: none;
}
@media (max-width: 820px) {
	.system-steps .nav-setup li a {
		height: 76px;
	}
}
@media (max-width: 480px) {
    .eco-progress-bar {
		display: none;
	}
	.mob-progress-bar {
		display: flex;
		.content {
			flex: 1 0 25%;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
			color: $grey-dark1;
			text-align: left;
		}
		.bar {
			flex: 1 0 75%;
			padding-top: 6px;
		}
		.progress {
			height: 6px;
			background-color: $grey2;
			border-radius: 20px;
		}
		.progress-bar {
			background-color: $blue1;
		}
	}
}
