@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";

.eco-results-wrapper {
  font-family: Arial, Helvetica, sans-serif !important;
  display: none;
  font-size:10px;
 .pdf-header {
  width: 900px !important;
  color: $red;
    .header-title {
      width: 100%;
      font-weight: 900;
   }
 }
}
.eco-resultsPdf-page1, .eco-resultsPdf-page2 {
  font-family: Arial, Helvetica, sans-serif !important;
  width: 900px;
  margin-left: 25px;
  padding: 0px 50px 0px 50px;
  .graph-break {
	  margin-top:25%;
  }
  .graph-break1 {
	margin-top:52%;
  }
  .graph-break2 {
	margin-top:38%;
  }
 .results-User-summary {
	padding: 20px 0;
	.title {
		font-weight: $fw-bold;
		font-size: 18px;
		text-align: left;
		padding: 10px 0 0 0;
	}
	.sub-title {
		font-weight: $fw-reg;
		font-size: 16px;
		text-align: left;
		padding: 0 0 10px 0;
	}
	.user-details {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 10px;
		.info {
			font-size: 14px;
			span {
				font-weight: $fw-bold;
			}
		}
	}
	.location-details {
		display: block;
		padding-bottom: 10px;
		.info {
			font-size: 14px;
			span {
				font-weight: $fw-bold;
			}
		}
	}
	.x-axis-arrow {
		img{
			height: 92px;
			width: 7px;				
		}
		padding-left: 2%;	

	}
	.y-axis-arrow {
		img{
			height: 7px;
			width: 131px;			
		}
		padding-left: 42%;	
	}
	}
	#resultsUserSummary {
		.info {
			font-size: 12px;
		}
	}
	#results-tab {
		.results-table .panel-body {
			.table-head {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 9px;
				font-weight: $fw-bold;
				background: transparent !important;
			}
			.table-data, .table-items.type.col-md-3 span {
				font-family: Arial, Helvetica, sans-serif;
				font-size: 10px;
				background: transparent !important;
			}
			.table-items.type.col-md-3, .table-data.col-md-3 {
				width: 24%;
			}
		}
	}
	#parameter-tab {
		.sensitivity-table .panel-body {
			.table-head {
				font-family: Arial, Helvetica, sans-serif;
			}
			.table-data {
				padding: 0 0 10px 15px;
				margin-top: 8px;
				font-family: Arial, Helvetica, sans-serif;
				display: flex !important;
				justify-content: center !important;
				text-align: center !important;
				&:first-child {
					justify-content: left !important;
					text-align: left !important;
				}
				.form-control {
					font-family: Arial, Helvetica, sans-serif;
					padding: 0;
					justify-content: center !important;
					text-align: center !important;
					border: 0;
					font-size: 14px;
					font-weight: $fw-bold;
				}
				.form-group{
					.hidden-item-pdf{
						display: none;
					}
				}
				.hidden-item-pdf{
					display: none;
				}
				.item-pdf-only{
					border: 0;
					font-size: 14px;
					background: transparent;
				}
				input[type=text] {
					border: 0;
					font-family: Arial, Helvetica, sans-serif;
					padding: 0;
					font-size: 14px;
					justify-content: center !important;
					text-align: center !important;
					font-weight: $fw-bold;
				}
			}
		}
	}
	.results-graph {
		font-family: Arial, Helvetica, sans-serif;
		.graph-info {
			font-size: 11px;
		}
		.graph-title {
			font-size: 16px;
			font-family: Arial, Helvetica, sans-serif;
		}
	}
}