@import "../../../../../assets/css/variables.scss";
@import "../../../../../assets/css/mixins.scss";

.eco-store-sizes {
	width: 100%;
	margin: 25px 0 0 0;
	.sub-head {
		font-size: 28px;
		text-align: left;
		font-weight: $fw-med;
		margin-bottom: 20px;
	}
	.selection-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.store-list {
			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				li {
					width: 180px;
					height: 180px;
					background: $white;
					box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
					align-items: center;
					justify-content: center;
					margin-right: 40px;
					display: inline-block;
					cursor: pointer;
					&:last-child {
						margin-right: 0;
					}
					.img-size {
						@include d-flex-center;
						height: 100%;
						padding: 10px;
						margin-bottom: 10px;
						cursor: pointer;
						img {
							max-width: 100%;
							height: auto;
						}
					}
					p.sub-title {
						text-align: center;
					}
					p.sub-title span {
						text-align: center;
						display: block;
					}
					sup {
						top: -0.5em;
					}
				}
				li.active {
					cursor: default;
					border: 1px solid $black;
				}
				li.active .sub-title {
					font-weight: $fw-bold;
					font-family: $font-family-sansmedium;
				}
				li.disable {
					cursor: not-allowed;
				}
				li.disable:hover {
					cursor: not-allowed;
				}
			}
		}
	}
	.accordion-item {
		&:last-of-type {
			.accordion-button {
				&:not(.collapsed) {
					background-color: $grey28 !important;
				}
				&:hover {
					background-color: $grey28;
					cursor: pointer !important;
				}
			}
		}
	}
}