@import "../../../assets/css/variables.scss";
@import "../../../assets/css/mixins.scss";
.warning-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.75);
	display: grid;
	place-items: center;
	transition: all 0.3s linear;
	visibility: visible;
	z-index: -1;
	.show-warning {
		visibility: hidden;
		z-index: 10;
	}
	.warning-container {
		background: $white;
		border-radius: 6px;
		border: 1px solid $grey18;
		width: 90vw;
		height: auto;
		max-width: 740px;
		display: grid;
		position: relative;
		padding: 15px 30px 25px 30px;
		min-height: 100px;
		p {
			font-weight: $fw-med;
			font-size: 14px;
			color: $grey-dark2;
            text-align: left;
		}
		p.label-red {
			color:$red;
			font-weight: $fw-reg;
		}
		p.label-red p  {		
			font-weight: $fw-reg;
		}
		p.label-red p:first-child  {
			color:$red;		
		}
		.no-label-red {
			color: $grey-dark1;
		}
		.modal-heading {
			@include d-flex-space-between;
			margin-bottom: 5px;
			.warning-title {
				font-weight: $fw-bold;
				font-family: $font-family-sansbold;
				font-size: 18px;
				line-height: 20px;
				color: $grey-dark1;
				margin: 0;
				padding-top: 5px;
				text-align: left;
			}
			.close-icon {
				font-size: 24px;
				text-align: right;
				cursor: pointer;
			}
		}
		.modal-heading-icon {
			@include d-flex-center;
			padding-bottom: 10px;
			.warning-title-icon {
				font-weight: $fw-bold;
				font-size: 16px;
				line-height: 20px;
				color: $grey-dark1;
				margin: 0;
				padding: 5px 0 0 0;
				text-align: center;
				display: flex;
			}
			.warning-icon {
				padding-right: 10px;
				display: flex;
				img {
					width: 25px;
				}
			}
		}
		.btn-container {
			@include d-flex-center;
			.btn {
				margin: 0 6px;
				font-weight: $fw-reg;
				padding: 10px 36px 11px;
				min-width: 160px;
				&:first-child {
					margin-left: 0;
				}
			}
		}
		.ModalDecriptionBox {
			margin-top: 5%;
			text-align: center;
			justify-content: center;
		}
	}
}
.warning-overlay.choose-simulation {
	z-index: 1;
}
.modal-content {
    border: 0px solid rgba(0,0,0,.2);
}
.color-red-idlh {
	color: $red;
	font-weight: $fw-reg;
}
@media only screen and (max-width: 600px) {
	.warning-overlay .warning-container {
		padding: 20px;
		top: 50px;
    	position: absolute;
	}
	.warning-overlay .warning-container .btn {
		margin: 0 0 10px 0;
		width: 100%;
    	display: inline-block;
	}
	.warning-overlay .warning-container .cta-mobile {
		display: block;
	}
	.warning-overlay .warning-container .cta-desktop {
		display: none;
	}
}