@import "../../../../../../assets/css/variables.scss";
@import "../../../../../../assets/css/mixins.scss";

.store-size-table {
	width: 100%;
	margin:0 auto 30px;
    .sub-head {
        @include sub-title;
		margin: 0 0 5px 0;
    }
	.panel-body {
	  padding: 0;
	  margin-top: 5px;
	  border: 1px solid $grey2;
	  .table-head {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: normal;
		align-items: normal;
		align-content: normal;
		border-bottom: 1px solid $grey2;
		@include accordion-table-head;
	  }
	  .table-items {
		display: flex;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		width: 33%;
		padding: 10px 0 10px 15px;
		border-right: 1px solid $grey2;
		&:last-child {
		  border-right: none;
		}
	  }
	  .table-data {
		display: block;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		order: 0;
		width: 33%;
		padding: 0;
        text-align: left;
        padding-left: 16px;
		margin: 0 auto;
		@include accordion-table-data;
		&:first-child {
		  padding-left: 0;
		}
		&:nth-child(2) {
			padding-left: 5px;
		}
		&:last-child {
		  padding-left: 10px;
		}
		img {
			max-width: 100%;
			height: auto;
		}
		.form-check-input {
		  width: 20px;
		  height: 16px;
		  margin-top: 0px;
		  vertical-align: inherit;
		  background-color: transparent;
		  background-repeat: no-repeat;
		  background-position: initial;
		  background-size: 20px;
		  border: transparent;
		  appearance: auto;
		}
	  }
	  .schematics {
		color: $blue;
		cursor: pointer;
		font-weight: $fw-bold;
	  }
	  .accordion-button {
		color: $grey-dark1;
		box-shadow: none;
		padding: 8px 10px 10px 18px;
		font-size: 14px;
		&:not(.collapsed) {
		  color: $grey-dark1;
		  box-shadow: none;
		  padding: 8px 10px 10px 18px;
		  font-size: 14px;
		  background-color: $grey28;
		  &::after {
			display: none;
		  }
		}
		&:not(.expand) {
		  &::after {
			display: none;
		  }
		}
	  }
      .accordion-content {
        @include d-flex;
		color: $grey-dark1;
		box-shadow: none;
		padding: 6px 0 8px;
		font-size: 14px;
		&:nth-of-type(even) {
			background-color: $grey6;
		}
      }
	}
	.accordion-body {
	  padding: 18px;
	  margin: 0 auto;
	  background-color: $white;
	  .list-content {
		display: flex;
	  }
	  .arch-details {
		padding: 5px 0;
		margin: 0;
		font-size: 14px;
		display: inline-block;
		.arch-diagram {
			padding-left: 50px;
		}
	  }
	}
	.form-check-input {
	  &:focus {
		border-color: none;
		outline: 0;
		box-shadow: none;
	  }
	}
	.accordion_form_main .accordion_div_main.selected button {
	  background-color: $grey28 !important;
	}
	.accordion_form_main {
	  .accordion_div_main {
		button {
		  background-color: $white;
		}
		&:nth-of-type(even) {
		  button {
			background-color: $grey6;
		  }
		}
	  }
	}
	.accordion-item {
		&:last-of-type {
		  .accordion-button {
			&:not(.collapsed) {
			  background-color:transparent;
			}
			&:hover {
				cursor: default;
			}
		  }
		}
	}
	.accordion-collapse.collapse.show {
	  background-color: $light-blue;
	}
}