@import "../../../../assets/css/variables.scss";

#wrapperContactForm {
	display: flex;
}
.contact-info {
	text-align: left;
	.contact-title {
		font-size: 30px;
		font-weight: $fw-reg;
		font-family: $font-family-sansmedium;
		color: $grey-dark1;
	}
	a {
		font-weight: $fw-bold;
	}
}
.mktoForm {
	width: 100%;
	.mktoFormRow {
		width: 46%;
		clear: right !important;
		float: left !important;
		margin: 0 25px 0 0;
	}
	.mktoFieldDescriptor.mktoFormCol {
		width: 100%;
	}
	.mktoFormCol {
		min-height: 3em;
		float: left;
		position: relative;
	}
	div {
		text-align: left;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: normal;
	}
	span {
		text-align: left;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: normal;
	}
	label {
		text-align: left;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: normal;
		margin-bottom: 8px !important;
	}
	p {
		text-align: left;
		margin: 0;
		padding: 0;
		font-size: 18px;
		line-height: normal;
		text-align: left;
		margin: 0;
		padding: 0;
		font-size: 16px;
		line-height: normal;
		color: $grey4;
	}
	.mktoOffset {
		float: left;
		height: 1.2em;
	}
	.mktoFieldWrap {
		padding-top: 15px;
		float: left;
		width: 100%;
		.mktoInstruction {
			display: none;
			display: none;
		}
	}
	.mktoRequiredField {
		label.mktoLabel {
			font-weight: normal;
			font-size: 16px;
			font-family: $font-family-sansmedium;
			padding-bottom: 10px;
			color: $grey-dark1;
		}
	}
	.mktoLabel.mktoHasWidth {
		font-size: 16px;
	}
	.mktoGutter {
		float: left;
		height: 1.2em;
	}
	input[type=text] {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	input[type=url] {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	input[type=email] {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	input[type=tel] {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	input[type=number] {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	input[type=date] {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	select.mktoField {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		-moz-appearance: none;
		text-indent: .01px;
		text-overflow: "";
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		padding: .2em 22px .2em .3em;
		background: $white url(../../../../assets/images/dropdown_arrow.webp) no-repeat scroll center right 10px;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	textarea.mktoField {
		-webkit-appearance: none;
		background: $white;
		line-height: 1.5em;
		padding: .2em .3em;
		min-height: 2.5em;
		height: auto;
		font-size: 18px;
		border: 1.5px solid $grey13;
		color: $grey-dark2;
		&:focus {
			outline: 0;
			border: 1px solid $blue !important;
			color: $grey-dark1;
		}
	}
	.mktoClear {
		clear: both;
		float: none;
	}
	.mktoLogicalField {
		border: 1px solid transparent;
	}
	input[type=checkbox] {
		width: 1em;
		height: 1em;
		opacity: 0;
		&:checked+label {
			&:before {
				content: "✓";
				background: $blue;
				color: $white;
				font-weight: $fw-bold;
				font-family: $font-family-sansbold;
				padding-left: 5px;
			}
		}
	}
	input[type=radio] {
		width: 1em;
		height: 1em;
		opacity: 0;
	}
	.mktoCheckboxList {
		>input {
			padding: 0;
		}
		>label {
			margin-bottom: .6em;
			user-select: none;
			margin-left: 2.5em;
			margin-top: .1em;
			line-height: 1.2em;
			display: block;
			min-height: 12px;
		}
	}
	input[type=checkbox]+label {
		&:before {
			content: "";
			position: absolute;
			width: 1.6em;
			height: 1.6em;
			margin-left: -2.5em;
			line-height: 1.6em;
			font-family: Arial,sans-serif;
			font-size: 13px;
			border: 1px solid $grey32;
			background: $white;
			display: inline-block;
		}
	}
	input[type=radio]+label {
		&:before {
			content: "";
			position: absolute;
			width: 1.6em;
			height: 1.6em;
			margin-left: -2.5em;
			line-height: 1.6em;
			font-family: Arial,sans-serif;
			font-size: 13px;
			border: 1px solid $grey32;
			background: $white;
			display: inline-block;
		}
	}
	.mktoButtonRow {
		.mktoButtonWrap {
			.mktoButton {
				background: $blue;
				color: $white;
				border: none;
				height: 62px;
				margin-top: 15px;
				padding: 10px 35px;
				width: 50%;
				border-radius: 0;
				text-shadow: none;
				font-weight: $fw-xbold;
				text-transform: uppercase;
				font-size: 14px;
				-webkit-box-shadow: none;
				box-shadow: none;
				&:hover {
					background: $blue-hov !important;
					color: $white;
					-webkit-box-shadow: none;
					box-shadow: none;
				}
			}
		}
	}
	span.mktoButtonWrap.mktoSimple {
		margin-left: 0 !important;
	}
	button.mktoButton {
		cursor: pointer;
		margin: 0;
	}
}
.mktoField.mktoHasWidth {
	width: 100% !important;
}
.mktoButtonRow {
	width: 100%;
	margin-top: 20px !important;
	display: inline-block !important;
	position: relative !important;
	text-align: center !important;
}