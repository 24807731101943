@import "../../../../assets/fonts/fonts.scss";
canvas.stackedgraph 
{
  height:30% !important; 
  width:90% !important;
  display: flex !important;
  margin-left:4%;
  
  background:#f2f2f2;
}
canvas#stacked-graph-pdf
{
  background:#f2f2f2;
}