@import "../src/assets/css/variables.scss";
@import "../src/assets/fonts/fonts.scss";
.global-header .breadcrumb {
  display: none
}
.App {
  text-align: center;
}
a, a:hover {
  outline: 0;
  text-decoration: none;
}
.loading-text {
  font-size: 14px;
  font-weight: $fw-bold;
  color: $grey1;
  padding-left: 5px;
}
sup {
  top: 0.7em;
}
input[type="text"] {
  padding: .375rem .75rem;
  border: 1px solid $grey2;
  &:focus {
    border-color: $grey2;
    box-shadow: none;
    outline: 0;
  }
}
.App-header {
  background-color: $grey-dark7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white;
}
.page-title {
  font-size: 2.2em;
  text-align: left;
  margin: 20px 0;
}
.main-wrapper {
	font-family: 'honeywell_sansregular';
	width: 100%;
	margin: 0 auto;
  color: $grey-dark1;
	.main-heading {
		display: flex;
		padding-bottom: 30px;
		font-style: normal;
		font-weight: 400;
		font-size: 40px;
		color: $grey-dark1;
	}
}
.eco-container {
  width: 96%;
  margin: 0 auto;
}
.btn {
  margin: auto 10px;
  padding: 12px 36px 11px;
  border: 1px solid $blue;
  border-radius: 0;
  font-style: normal;
  font-weight: $fw-bold;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  min-width: 164px;
  letter-spacing: 0.5px;
}
.btn-disabled {
  color: $grey1;
  background-color: $grey2;
  border: 1px solid $grey2;
  cursor: not-allowed;
  pointer-events: all !important;
  &:hover {
		color: $grey1;
	}
}
.btn-primary {
	color: $blue;
	background-color: $white;
	&:hover {
		color: $blue-hov;
		background-color: $white;
    border: 1px solid $blue-hov;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn-secondary {
	color: $white;
	background-color: $blue;
  border: 1px solid $blue;
	&:hover {
		color: $white;
		background-color: $blue-hov;
		border: 1px solid $blue-hov;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn-default {
  color: $white;
  background-color: $grey-dark1;
  border: 1px solid $grey-dark1;
	&:hover {
		color: $white;
		background-color: $grey-dark1;
		border: 1px solid $grey-dark1;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn-black {
	color: $white;
	background-color: $grey-dark1;
	&:hover {
		color: $grey-dark1;
		background-color: $white;
    border: 1px solid $grey-dark1;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn-white {
	color: $grey-dark1;
	background-color: $white;
	&:hover {
		color: $white;
		background-color: $grey-dark1;
    border: 1px solid $white;
	}
  &:focus {
    box-shadow: none;
  }
}
.btn.btn-black:hover {
  color: $white;
	background-color: $grey-dark1;
  cursor: default;
}
.btn.btn-primary.disabled {
  color: $grey1;
  background-color: $grey2;
  border: 1px solid $grey2;
  cursor: not-allowed;
  pointer-events: all !important;
  &:hover {
		color: $grey1;
	}
}
.footer-content {
	.button.button--primary {
		a {
			background: transparent;
			color: $white;
			border: 2px solid $white;
			font-size: 14px;
			line-height: 18px;
			padding: 15px 5px;
			word-break: break-word;
			width: 232px;
			letter-spacing: 0.5px;
			margin: 0 0 0 4px;
			text-transform: uppercase;
			text-align: center;
			font-weight: $fw-xbold;
			transition: all 0.3s ease;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			&:hover {
				border: 2px solid $blue-hov;
				background: transparent;
				color: $blue-hov;
			}
		}
	}
  ul.footer--links__list.hidden {
    padding-left: 0;
  }
  .footer--links__title {
      text-align: left;
  }
  .footer--links__socials {
      padding-left: 0;
  }
}
@media only screen and (min-width: 1025px) {
  .main-wrapper {
    .col-md-9.col-sm-12 {
      flex: 0 0 auto;
      width: 70%;
    }
    .col-md-3.col-sm-12 {
      flex: 0 0 auto;
      width: 30%;
    }
  }
}
@media only screen and (min-width: 1281px) {
  .main-wrapper {
    .col-md-9.col-sm-12 {
      flex: 0 0 auto;
      width: 73%;
    }
    .col-md-3.col-sm-12 {
      flex: 0 0 auto;
      width: 27%;
    }
  }
}
@media (min-width: 768px) {
  .footer-content {
    .pl-md-3, .px-md-3 {
        padding-left: 1rem !important;
    }
    .footer-content__bottom-link {
        text-align: left;
    }
    .footer-content__copyright {
        text-align: left;
    }
  }
}
@media only screen and (max-width: 952px) {
  .main-wrapper {
    width: 94%;
  }
}
@media only screen and (max-width: 820px) {
  .main-wrapper {
    .col-md-9.col-sm-12 {
      flex: 0 0 auto;
      width: 56%;
    }
    .col-md-3.col-sm-12 {
      flex: 0 0 auto;
      width: 44%;
    }
    .main-heading {
      padding-bottom: 20px;
      font-size: 2.0em;
    }
  }
}
@media only screen and (max-width: 480px) {
  .main-wrapper {
    width: 92%;
    .main-heading {
      font-size: 22px;
      padding-bottom: 20px;
    }
    .col-md-9.col-sm-12 {
      width: 100%;
    }
    .col-md-3.col-sm-12 {
      width: 100%;
    }
  }
  .btn {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
}